body {
  margin: 0;
  font-family: "Cerebri Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, #root, .main-content {
  height: 100%;
}


h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url(./assets/fonts/cerebrisans/cerebrisans-medium.woff) format("woff");
}


.material-icons {
  font-size: 16px;
}

button .spinner-border {
  width: 1.375rem !important;
  height: 1.375rem !important;
  color: #fff !important;
}


.fill {
  display: flex;
  position: relative;
}


/*
.avatar {
  width: 20%;
  max-width: 24px;
  border-radius: 50%;
  background-color: #00f;
  position: absolute;
}

*/
